
  import { defineComponent, onMounted, onUnmounted, watch } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { unref, ref } from "vue";
  import Vue from "vue";
  import { reactive } from "vue";
  import { getMerchantsRequest } from "@/latipay/apis/services/MerchantsService";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { EnumsConstant } from "@/store/enums/constants";
  import { getWalletsRequest } from "@/latipay/apis/services/WalletsService";
  import { ElMessage } from "element-plus";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";
  import { WalletsResponse } from "@/latipay/apis/models/wallet/WalletsResponse";
  import {
    getWalletStatementsRequest,
    postWalletStatementRequest
  } from "@/latipay/apis/services/WalletStatementsService";
  import { PostWalletParameter } from "@/latipay/apis/models/walletStatement/WalletStatementsResponse";


  export default defineComponent({
    name: "addWalletAdjustment",
    components: {},


    setup() {
      const store = useStore();
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(1000);
      const adjustPageNumber = ref(1);
      const adjustPageSize = ref(20);
      const stepOneFormRef = ref();
      const stepTwoFormRef = ref();
      const stepIndex = ref(0);

      const loadingRef = ref(false);
      const showHistory = ref(false);
      const currencies = EnumsConstant.CurrencyCode;


      const stepOneForm = reactive({
        merchantId: "",
        walletId: "",
        // currency: ""
      });

      const stepTwoForm = reactive({
        adjustType: "",
        balanceType:"",
        amount: "",
        reason: ""
      });

      const successAlert = (message => {
        ElMessage({
          showClose: true,
          message: message,
          type: "success"
        });
      });

      const failAlert = (message => {

        ElMessage.error(message)
      });

      const {
        merchantsRef,
        getMerchants
      } = getMerchantsRequest();


      const getMerchantData = async () => {
        loadingRef.value = true;

        // await updateQueries();
        await getMerchants(
          null,
          null,
          null,
          null,
          'Verified',
          true,
          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loadingRef.value = false;

        console.log(merchantsRef);
      };

      const {
        walletsRef,
        getWallets
      } = getWalletsRequest();


      const getWalletData = async () => {
        loadingRef.value = true;

        // await updateQueries();
        await getWallets(
          +stepOneForm.merchantId,
          null,
          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loadingRef.value = false;

        console.log(walletsRef);
      };

      const handleMerchant = async () => {
        console.log(stepOneForm.merchantId);
        if(stepOneForm.merchantId){
          await getWalletData();
        }

      };
      const onToStepTwo = async () => {

        const form = unref(stepOneFormRef);
        if (!form) {
          return
        }
        try {
          await form.validate();
          if (stepOneForm.merchantId && stepOneForm.walletId ) {
            stepIndex.value = 1;


          }
        } catch (err) {
          console.log(err)
        }

      };

      const onToStepThree = async () => {


        const form = unref(stepTwoFormRef);
        if (!form) {
          return
        }
        try {
          await form.validate();
          if ( +stepTwoForm.amount <= 0 ) {
            failAlert("Input amount must be larger than 0.");

          }else {

            let newAdjust = new PostWalletParameter();
            newAdjust.balanceType = stepTwoForm.balanceType;
            newAdjust.merchantId = +stepOneForm.merchantId;
            newAdjust.walletId = +stepOneForm.walletId;
            if (stepTwoForm.adjustType === 'increase') {
              newAdjust.amount = +stepTwoForm.amount;
            }else if (stepTwoForm.adjustType === 'decrease'){
              newAdjust.amount = +stepTwoForm.amount * -1;
            }
            newAdjust.remarks = stepTwoForm.reason;


            await postAdjustData(newAdjust);
          }
        } catch (err) {
          console.log(err)
        }

      };
      const { postErrorRef, postWalletStatement } = postWalletStatementRequest();
      const postAdjustData = async ( data: PostWalletParameter) => {
        loadingRef.value = true;
        await postWalletStatement(data);

        if (!postErrorRef.value) {
          loadingRef.value = false;

          successAlert("Adjust wallet balance successfully!");
          stepIndex.value = 2;
          stepTwoForm.adjustType = "";
          stepTwoForm.amount = "";
          stepTwoForm.balanceType = "";
          stepTwoForm.reason = "";

        }else {
          failAlert("Something wrong happened! Please have a check.");
          loadingRef.value = false;
        }


      };
      const backToStepOne = async () => {
        stepIndex.value = 0;
      };

      const displayTransaction = async () => {
        showHistory.value = true;
        await getTransactionsData();
      };

      const {
        walletStatementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getWalletStatements
      } = getWalletStatementsRequest();


      const getTransactionsData = async () => {
        loadingRef.value = true;

        // await updateQueries();
        await getWalletStatements(
          null,
          stepOneForm.walletId,
          'Adjust',
          null,

          sort.value,
          adjustPageNumber.value,
          adjustPageSize.value
        );
        reinitializeComponents();

        loadingRef.value = false;

        console.log(walletStatementsRef);
      };

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        adjustPageSize.value = val;
        await getMerchantData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        adjustPageNumber.value = val;
        await getMerchantData();
      };

      const checkSuccess = async () => {
        stepIndex.value = 1;
        displayTransaction();
      };

      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getMerchantData();
      });

      // const onToStepTwo = () => {
      //   stepIndex = 1;
      //   console.log(stepIndex);
      // };


      return {
        getIllustrationsPath,
        stepOneForm,
        stepTwoForm,
        merchantsRef,
        currencies,
        loadingRef,
        handleMerchant,
        walletsRef,
        onToStepTwo,
        stepIndex,
        stepOneFormRef,
        onToStepThree,
        backToStepOne,
        showHistory,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        displayTransaction,
        updatePageSize,
        updatePageNumber,
        stepTwoFormRef,
        checkSuccess,
        DateTimeUtils,
        walletStatementsRef

      };
    },
    methods: {
      // onToStepTwo() {
      //   this.stepIndex = 1;
      //
      // },
      //
      // onToStepThree() {
      //   this.stepIndex = 2;
      //
      // }
    }

  });
